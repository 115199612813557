import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
// import Info from "../images/Info-1_v2.inline.svg"
// import Info from "../images/Info-1_v1.inline.svg"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import * as Icons from "../components/Icons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUsGraphics from "../images/artwork/ContactUs-Graphics_v4.svg"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const init = {
  name: "",
  company: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
  subscribe: false,
}

const ContactPage = () => {
  const [formData, setFormData] = useState(init)
  const [hidden, setHidden] = useState("")
  const [error, setError] = useState(false)
  const [errorMessages, setErrorMessages] = useState({})
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    setCharCount(formData.message.length)
  }, [formData.message])

  const getCharMessage = () => {
    if (charCount > 500) {
      let exceeded = charCount - 500
      return (
        <span
          style={{
            marginBottom: 0,
            color: "red",
          }}
        >
          {exceeded} characters too many
        </span>
      )
    }
    let remaining = 500 - charCount
    return (
      <span
        style={{
          marginBottom: 0,
          color: "#222222",
        }}
      >
        {remaining} characters remaining
      </span>
    )
  }

  const handleChange = e => {
    const {
      target: { value, name },
    } = e
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCheckbox = e => {
    const {
      target: { checked, name },
    } = e
    setFormData(prev => ({
      ...prev,
      [name]: checked,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    let isError = false
    let errorMessages = {}
    if (hidden.length > 0) {
      isError = true
    }
    if (formData.name === "") {
      isError = true
      errorMessages["name"] = "Please provide your name"
    }
    if (formData.email === "") {
      isError = true
      errorMessages["email"] = "Please provide your email"
    }
    if (formData.subject === "") {
      isError = true
      errorMessages["subject"] = "Please choose a subject for this enquiry"
    }
    if (formData.message === "") {
      isError = true
      errorMessages["message"] = "Please enter a message"
    }
    if (formData.message.length > 500) {
      isError = true
      errorMessages["message"] = "Message has exceeded the character limit"
    }
    if (!isError) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": form.getAttribute("name"), ...formData }),
      })
        .then(() => {
          navigate("/success")
          // setFormData(init)
          // setError(false)
          // setErrorMessages({})
        })
        .catch(error => alert(error))
    } else {
      // console.log("error found")
      setError(true)
      setErrorMessages(errorMessages)
    }
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Containers.PageContainer>
        <Containers.SectionContainer
          style={{
            flexDirection: "column",
          }}
        >
          <ContactBg />
          <Header>
            <h1>Connect with Us</h1>
            <p>
              We'd love to hear from you! <br />
              Connect with us using the form below and we'll be happy to assist
              you with your enquiries.
            </p>
          </Header>
          <FormContainer>
            <div className="formContainer" style={{ padding: "7% 7% 0% 7%" }}>
              <div style={{ marginBottom: "1.875rem" }}>
                <span style={{ color: "red" }}>*</span>{" "}
                <span style={{ color: "inherit", opacity: "0.54" }}>
                  Required Fields
                </span>
              </div>
              <form
                name="contact"
                onSubmit={handleSubmit}
                method="post"
                data-netlify="true"
                netlify-honeypot="hidden"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                <input type="hidden" name="form-name" value="contact" />
                <FormFieldGrid>
                  <div
                    style={{
                      display: "none",
                    }}
                  >
                    <input
                      type="text"
                      name="hidden"
                      value={hidden}
                      onChange={e => setHidden(e.target.name)}
                      placeholder="Bot check"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <h5 style={{ marginBottom: 20 }}>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </h5>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="What's your full name?"
                      // required
                    />
                    {error && errorMessages["name"] && (
                      <p style={{ marginBottom: 0, color: "red" }}>
                        {errorMessages["name"]}
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <h5 style={{ marginBottom: 20 }}>Company</h5>
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      placeholder="What's your company's name?"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <h5 style={{ marginBottom: 20 }}>
                      Email <span style={{ color: "red" }}>*</span>
                    </h5>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="someone@example.com"
                      // required
                    />
                    {error && errorMessages["email"] && (
                      <p style={{ marginBottom: 0, color: "red" }}>
                        {errorMessages["email"]}
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 20,
                    }}
                  >
                    <h5 style={{ marginBottom: 20 }}>Phone Number</h5>
                    <input
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                </FormFieldGrid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20,
                  }}
                >
                  <h5 style={{ marginBottom: 20 }}>
                    Subject <span style={{ color: "red" }}>*</span>
                  </h5>
                  <select
                    name="subject"
                    onChange={handleChange}
                    value={formData.subject}
                  >
                    <option value="" disabled selected hidden>
                      What do you need help with?
                    </option>
                    <option value="enterprise">Enterprise</option>
                    <option value="features">Features</option>
                    <option value="pricing">Pricing</option>
                    <option value="others">Others</option>
                  </select>
                  {error && errorMessages["subject"] && (
                    <p style={{ marginBottom: 0, color: "red" }}>
                      {errorMessages["subject"]}
                    </p>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 30,
                  }}
                >
                  <h5 style={{ marginBottom: 20 }}>
                    Message <span style={{ color: "red" }}>*</span>
                  </h5>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{
                      resize: "none",
                    }}
                    maxLength="600"
                    // required
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <p  style={{ marginBottom: 0 }}>
                      Maximum 280 characters {getCharMessage()}
                    </p> */}
                    <p style={{ marginBottom: 0, marginTop: 10 }}>
                      {getCharMessage()}
                    </p>
                    {error && errorMessages["message"] && (
                      <p
                        style={{
                          marginBottom: 0,
                          color: "red",
                        }}
                      >
                        {errorMessages["message"]}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Checkbox
                    name="subscribe"
                    type="checkbox"
                    value={formData.subscribe}
                    onChange={handleCheckbox}
                  />
                  <Content>
                    I’d like to be informed on product and company updates from
                    Uuoni.
                  </Content>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: 30,
                  }}
                >
                  <InfoIcon />
                  <Content>
                    By submitting this contact form, I consent to the use of my
                    personal information as specified in Uuoni
                    <Link to="/terms" style={{ marginLeft: 5, marginRight: 5 }}>
                      Terms of Service
                    </Link>
                    and
                    <Link to="/privacy" style={{ marginLeft: 5 }}>
                      Privacy Policy
                    </Link>
                    .
                  </Content>
                </div>
                <Buttons.ButtonNormal
                  type="submit"
                  style={{
                    alignSelf: "center",
                    marginBottom: 40,
                  }}
                >
                  Send
                </Buttons.ButtonNormal>
              </form>
            </div>
          </FormContainer>

          {/* </ContactBg> */}
        </Containers.SectionContainer>
        <Containers.SectionContainer style={{ flexDirection: "column" }}>
          {/* <div
            style={{
              alignSelf: "center",
              marginTop: "auto",
              marginBottom: "auto",
              textAlign: "center",
              width: "80%",
            }}
          >
            <h2>Frequently Asked Questions</h2>
            <p >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
            </p>
          </div>
          <Containers.FAQContainer
            style={{ marginTop: "5rem", alignSelf: "center" }}
          >
            {Faqs.map((item, index) => (
              <div key={index}>
                <h5>{item.question}</h5>
                <p >{item.answer}</p>
              </div>
            ))}
          </Containers.FAQContainer> */}
          {/* <InfoContainer>
            <Email style={{ width: "10rem", height: "10rem" }} />
            <h5>Contact Information</h5>
            <p >
              For general queries, including partnership opportunities, please
              email
              <a
                href="mailto:support@uuoni.com"
                style={{
                  marginLeft: "0.3rem",
                }}
              >
                support@uuoni.com
              </a>
            </p>
          </InfoContainer> */}
        </Containers.SectionContainer>
      </Containers.PageContainer>
    </Layout>
  )
}

export default ContactPage

const ContactBg = styled.div`
  position: absolute;
  background-image: url(${ContactUsGraphics});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  transform: scale(1.11);
  top: 6%;
  z-index: -10;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-size: contain;
    transform: scale(1.15);
    top: -7.5%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1025px) {
    width: 70%;
    margin: auto auto 3rem;

    p {
      width: 60%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 70%;
    margin: auto auto 3rem;

    p {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    text-align: left;
    width: 100%;

    h1 {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`

const FormContainer = styled.div`
  background: var(--theme-ui-colors-white, #ffffff);
  box-shadow: 5px 2px 4px rgba(46, 41, 51, 0.08);
  padding: 4px;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  border-radius: 30px;

  @media screen and (min-width: 1025px) {
    width: 75%;
    margin: auto auto 80px;
  }

  @media screen and (max-width: 1024px) {
  }
`

const FormFieldGrid = styled.div`
  display: grid;
  column-gap: 2.5rem;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1024px) {
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;

  @media screen and (min-width: 1025px) {
    width: 35%;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const Content = styled.p`
  @media screen and (min-width: 768px) {
  }

  @media screen and (max-width: 767px) {
    width: 86%;
  }
`

const Checkbox = styled.input`
  height: 2rem;
  width: 2rem;
  margin-right: 4%;
`

const InfoIcon = styled(Icons.HoverInfoIcon)`
  margin-right: 4%;
`
